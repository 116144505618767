<template>
  <div class="d-flex flex-column py-4">
    <div class="top-main">
      <dialog-invite :refresh="getInvitationList" />
    </div>

    <div class="header-top">
      <h2 class="view-title">Invitations</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        <a class="pr-2 text--darken-3 text--secondary" @click="goToAction(item)">
          {{ formatDate(item.created_at) }}
        </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="cont-actions">
          <v-icon color="error" @click="showDialog(item)"> mdi-delete </v-icon>
        </div>
      </template>
    </v-data-table>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-delete
          :show.sync="dialog_delete.show"
          :item="dialog_delete.item"
          title="Are you sure to delete your invitation?"
          description="The invitation will be cancelled and any tokens sent will be invalid, would you like to continue?"
          :delete="cancelInvitation"
        ></dialog-delete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
//components
import DialogDelete from '@/components/dialog/DialogDelete.vue'
import DialogInvite from './component/DialogInvite.vue'

export default {
  components: {
    DialogDelete,
    DialogInvite,
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Client ID', value: 'client_id', sortable: true },
        { text: 'Send by', value: 'send_by', sortable: true },
        { text: 'Role', value: 'role_name', sortable: true },
        { text: 'Expiration Date', value: 'expire_date', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: {
        item: {},
        show: false,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getInvitationList()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getInvitationList()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    ...mapActions({
      getInvitations: 'invitations/getInvitations',
      delete: 'invitations/delete',
      setSnackbar: 'snackbar/set',
    }),
    async getInvitationList() {
      this.loading = true
      await this.getInvitations(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async cancelInvitation() {
      this.loading = true
      this.dialog_delete.show = false

      await this.delete(this.dialog_delete.item.id)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              this.setSnackbar({
                status: true,
                text: 'Record was successfully deleted.',
                color: 'primary',
              })
            }
          }

          this.dialog_delete.item = null
          this.getInvitationList()
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    showDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
  },
}
</script>

<style scoped>
.top-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px;
  }
  .url-domain {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
