<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          <v-icon>{{ icons.mdiPlus }}</v-icon> New User
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Invite a new user </v-card-title>

        <v-divider />

        <v-card-text>
          <v-form ref="form" class="form">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              class="mb-3"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
            />
            <v-select
              label="Select Role"
              v-model="role"
              :items="roleItems"
              item-text="name"
              item-value="name"
              outlined
              :rules="[validators.required]"
            />
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
          <v-btn color="primary" @click="send" :disabled="sending">
            <template v-if="!sending">Send Invitation <v-icon dark right> mdi-send</v-icon> </template>
            <v-progress-circular v-else indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, emailValidator, passwordValidator } from '@core/utils/validation'
import { mdiPlus } from '@mdi/js'

export default {
  props: {
    refresh: Function,
  },
  data() {
    return {
      dialog: false,
      roleItems: [],
      email: '',
      role: null,
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },
      icons: {
        mdiPlus,
      },
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    ...mapActions({
      getInvitationRoles: 'role/getInvitationRoles',
      setSnackbar: 'snackbar/set',
      sendInvitation: 'invitations/sendInvitation',
    }),
    async getRoles() {
      this.loading = true
      await this.getInvitationRoles()
        .then(resp => {
          if (resp && resp.data) {
            this.roleItems = resp.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async send() {
      if (this.$refs.form.validate()) {
        this.sending = true
        const formData = {
          email: this.email.trim(),
          role: this.role,
        }

        await this.sendInvitation(formData)
          .then(resp => {
            if (resp && resp.data) {
              this.setSnackbar({
                status: true,
                text: 'Invitation has been sent.',
                color: 'primary',
              })

              this.refresh()
            }
          })
          .catch(error => {
            console.log(error)
            this.setSnackbar({
              status: true,
              text: 'Failed to send invitation.',
              color: 'error',
            })
          })
          .finally(() => {
            this.$refs.form.reset()
            this.sending = false
            this.dialog = false
          })
      }
    },
  },
}
</script>

<style lang="css" scoped>
.form {
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}
</style>

